<template>

  <div class="mini_game_bet">
    <div class="mini_game">
      <div class="m3">
        <div class="mini_fram">
          <iframe id="token-gamefram" scrolling="no" frameborder="0" allowtransparency="true" allowfullscreen
                  :src="iframUrl"></iframe>
        </div>
      </div>
    </div>
  </div> <!--./mini_game_bet end--->

</template>

<script>
import leisureConst from "@/common/leisureConst";
import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "@/store/mutation-types";
import {getTgame365Hash} from "@/network/userRequest";

export default {

  name: "Tgame365FW",
  components: {},
  data() {
    return {
      kind: leisureConst.LEISURE_KIND_TGAME365_FW,
      gtype: '',
      iframUrl: ''
    }
  },
  methods: {
    initLeisureGameArttributeConfig() {

    }
  },
  created() {
    getTgame365Hash().then(res => {
      console.log(res)
      let uid = res.data.uid;
      let hash = res.data.hash;
      this.iframUrl = 'https://api.tgame365.com/api/?gtype=fw&uid=' + uid + '&hash=' + hash
      console.log(this.iframUrl)
    })
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP, leisureConst.LEISURE_COMP_TOKENGAME);
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID, this.kind);
  }
}
</script>

<style scoped>
@media screen and (max-width: 1024px) {
  #token-gamefram {
    height: 420px !important;
  }
}
</style>